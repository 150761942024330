p {
    margin: 0;
}

.sorry-title {
    margin-top: 0;
    margin-bottom: 8px;
    text-align: center;
    font-size: 24px;
}

.error-block {
    text-align: center;
}

.error-icon {
    width: 96px;
    height: 96px;
}

.sub-paragraph {
    text-align: center;
    margin-bottom: 40px;
}

.subtitle {
    font-size: 20px;
    margin-bottom: 8px;
}