body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #20273d;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

p {
  line-height: 22px;
}

.link {
  text-decoration: none;
  color: #448aff;
}