.title {
    font-size: 24px;
    margin-bottom: 24px;
    margin-top: 0;
}

.benefit {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
}

.benefit_icon {
    margin-right: 10px;
}

.benefit_text {
    color: #20273d;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    padding-right: 8px;
}

.button {
    text-align: center;
    text-decoration: none;
    color: #fff;
    background: #1A98E4;
    border-radius: 28px;
    height: 56px;
    line-height: 56px;
    width: 223px;
    margin: 36px auto 0;
    display: block;
}

/*.button {*/
/*    position: fixed;*/
/*    text-decoration: none;*/
/*    bottom: 0;*/
/*    left: 8px;*/
/*    right: 8px;*/
/*    border: 0;*/
/*    height: 56px;*/
/*    border-radius: 4px;*/
/*    background-color: #448aff;*/
/*    line-height: 56px;*/
/*    text-align: center;*/
/*    text-transform: uppercase;*/
/*    font-size: 14px;*/
/*    font-weight: 500;*/
/*    letter-spacing: 0.5px;*/
/*    color: #ffffff;*/
/*    margin-bottom: 10px;*/
/*}*/