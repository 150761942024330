.error {
    font-size: 14px;
    display: block;
    color: red;
}

.input-group {
    margin-bottom: 20px;
}

.input-group__first {
    width: 100%;
}

.input-group__child {
    width: 45%;
}

.card-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.input {
    box-sizing: border-box;
    width: 100%;
    display: block;
    margin: 0;
    padding: 6px;
    border: 0;
    font-size: 22px;
    font-weight: bold;
    border-bottom: 1px solid #a7a7a7;
    outline: none;
}

.input_text {
    font-size: 16px;
    padding-left: 0;
}

.input:focus {
    border-bottom: 1px solid #448aff;
}

.input::placeholder {
    font-weight: normal;
    color: #cecece;
}

.input_text::placeholder {
    color: #a7a7a7;
}

label {
    color: #20273d;
    font-size: 14px;
}

.secure {
    font-size: 14px;
    padding-left: 20px;
    background-image: url(../assets/icons/safety-gray.svg);
    background-position: left -5px bottom 70%;
    background-repeat: no-repeat;
    color: #777e96;
}

.email-desc {
    padding-top: 10px;
    line-height: normal;
    color: #a7a7a7;
    font-size: 12px;
}